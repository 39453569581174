<template>
  <div id="casebox">
    <div class="hadheight"></div>
    <Header />
    <div class="casebox-sec1 base100">
        <div class="basecont">
            <div class="casebox-tit">
                <h4 class="tit">{{title}}</h4>
                <div class="gt">
                    <!-- <select name="modelField[addressshen]" id="province" @change="changeProvince()" v-model="sheng">
                        <option value="">请选择省</option>
                        <option :value="item.value" :data-mu="index" v-for="(item,index) in mapString" :key="index">{{item.label}}</option>
                    </select>
                    <select name="modelField[addressshi]" id="city" @change="changeCity()" v-model="shi">
                        <option value="">请选择市</option>
                        <option :value="item.value" :data-mu="index" v-for="(item,index) in cityString" :key="index">{{item.label}}</option>
                    </select>
                     <select name="modelField[addressqu]"  @change="changeQu()"  id="region" v-model="qu">
                        <option value="">请选择区</option>
                        <option :value="item.value" :data-mu="index" v-for="(item,index) in districtString" :key="index">{{item.label}}</option>
                    </select> -->
                    <select v-for="item in selectCenter" 
                      :key="item.id" 
                      v-model="item.xuanzhong"
                      @change="loadAjax()">
                        <option value="">{{item.catname}}</option>
                        <option v-for="it in item.child" 
                                :key="it.id"
                                :value="it.id">
                          {{it.title}}
                        </option>
                    </select>
                </div>
            </div>
          <template v-if="loading == 0">
            <div class="weeb_load basefont40">加载中....</div>
          </template>
           <template v-else-if="loading == 1">
             <div class="cont">
                <div class="item" v-for="(item, index) in casedata" :key="index">
                    <router-link :to="{name:'Caseinfo',query:{id:item.id}}" :title="item.title" class="list">
                        <div class="img">
                        <img :src="item.thumb" />
                        </div>
                        <div class="font">
                        <div class="tit">
                            {{ item.title }}
                        </div>
                        </div>
                    </router-link>
                </div>
                
            </div>
            <el-pagination
            hide-on-single-page
            @current-change="ajaxCurrentChange"
            layout="prev, pager, next"
            :page-size="limit"
            :total="total" class="basepage">
            </el-pagination>
           </template>
           <template v-else>
            <div class="weeb_load basefont40">没有内容</div>
           </template>
        </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import ditu from '@/assets/js/ditu.json';

export default {
  name: "Case",
  components: {
    Header,
    Footer
  },
  data(){
    return {
      loading: 0,
      casedata:[],
      page: 1,
      limit: 6,
      total: 0,
      area: "",
      sheng: '',
      shi: '',
      qu: '',
      selectCenter:[],
      mapString: ditu, //省份
      cityString: [], //市
      districtString: [], //区
      title: ''
    }
  },
  created () {
        var _this = this;
      /* 加载地区下拉 */
      this.$axios.post('/api/Article/getCaseAreaList').then(function(res){
        if(res.code==1){
          _this.title = res.data.title;
          res.data.list.map( it => {
            return it.xuanzhong = "";
          });
          _this.selectCenter = res.data.list;
          
        }
        
      }).catch(function(error){
          console.log(error);
      });
      /* 初始化加载列表 */
      this.loadAjax();

  },
  mounted(){
    
  },
  methods: {
    ajaxCurrentChange(val){
      this.page=val;
      this.$nextTick(() => {
        this.loadAjax();
      });
    },
    loadAjax(){  //初始化加载
      var _this = this;
      
      // if(this.sheng){
      //   let arrt = [this.sheng,this.shi,this.qu].filter( it => {
      //     return it != '';
      //   } );
      //   let area = arrt.join("/");
      //   _this.area = area;
      // }else{
      //   _this.area = "";
      // }
      this.$axios.post('/api/Article/getCaseList',{
        page:_this.page,
        limit:_this.limit,
        screen_one: this.selectCenter.length?this.selectCenter[0].xuanzhong:"",
        screen_two: this.selectCenter.length?this.selectCenter[1].xuanzhong:"",
        // area:_this.area
      }).then(function(res){
          if(res.data.data!=''){
            _this.loading = 1;
          }else{
            _this.loading = 2;
          }
          _this.casedata = res.data.data; //数据添加
          _this.total=res.data.total;  //总条数
      }).catch(function(error){
          console.log(error);
      });
    },
    selectVlaue1(event){
      this.page=1;
      this.loadAjax();
    },
    /* 省份联动 */
    changeProvince(){
        if(this.sheng!=""){
            this.mapString.filter(v=>{
                if(v.value==this.sheng){
                    this.cityString = v.children;
                    this.shi="";
                    this.qu="";
                }
            });
        }else{
            this.shi="";
            this.qu="";
            this.cityString = {};
            this.districtString = {};
        }
        this.page=1;
        this.loadAjax();
    },
    /* 市 */
    changeCity(){
        if(this.shi!=""){
           this.cityString.filter(v=>{
              if(v.value==this.shi){
                this.districtString = v.children;
                this.qu="";
              }
          });
          this.page=1;
          this.loadAjax();
        }else{
          this.qu="";
          this.districtString = {};
        }
        
    },
    /* 区 */
    changeQu(){
      this.page=1;
      this.loadAjax();
    }
  }
}
</script>
